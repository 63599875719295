import { forwardRef, useContext } from 'react';
import { cssMerge } from '@volvo-cars/css/utils';
import { Hint } from './hint';
import { RadioContext } from './radio-group';
import { type BaseInputProps, type GlobalHTMLAttributes } from './types';
import { useIds } from './use-ids';

export interface RadioProps extends GlobalHTMLAttributes, BaseInputProps {
  /**
   * Concise label for the radio.
   */
  label: string;

  /**
   * The value that will be submitted with the form data if the radio is selected.
   *
   * @default 'on'
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#data_representation_of_a_radio_group
   */
  value?: string;
}

/**
 * Radios allow the user to select a single option from a group.
 *
 * Radios are designed to be used with the RadioGroup component.
 *
 * A Radio is a controlled component if the `value` is set on the parent RadioGroup.
 */
export const Radio = forwardRef<HTMLInputElement, RadioProps>(function Radio(
  {
    label,
    id,
    hint,
    hidden,
    dir,
    lang,
    translate,
    slot,
    value,
    className,
    style,
    ...props
  }: RadioProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { inputId, hintId } = useIds(id);
  const {
    value: groupValue,
    defaultValue: groupDefaultValue,
    ...groupProps
  } = useContext(RadioContext);

  let checked: boolean | undefined;
  let defaultChecked: boolean | undefined;
  if (typeof groupValue !== 'undefined') {
    checked = value === groupValue;
  } else if (
    typeof groupDefaultValue !== 'undefined' &&
    value === groupDefaultValue
  ) {
    defaultChecked = true;
  }

  return (
    <div
      id={id}
      className={cssMerge('flex-row', className)}
      hidden={hidden}
      dir={dir}
      lang={lang}
      translate={translate}
      slot={slot}
      style={style}
    >
      <input
        {...props}
        {...groupProps}
        ref={ref}
        checked={checked}
        defaultChecked={defaultChecked}
        type="radio"
        id={inputId}
        value={value}
      />
      {hint ? (
        <div className="flex-col stack-4 ml-8">
          <label htmlFor={inputId}>{label}</label>
          <Hint id={hintId}>{hint}</Hint>
        </div>
      ) : (
        <label htmlFor={inputId} className="ml-8">
          {label}
        </label>
      )}
    </div>
  );
});
